import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, Button, Card, Modal, Form, OverlayTrigger, Tooltip,Tab, Nav } from 'react-bootstrap'
import CForm from './cform';

function ContactFormTemplates(props) {
    const [templates, setTemplates] = useState([])
    const [mode, setMode] = useState(2)
    const [selectedTemplate, setSelectedTemplate] = useState({})
    const [selectedJob, setSelectedJob] = useState(0)
    const [showCForm, setShowCForm] = useState(false)
    const [updatingdata, setUpdatingdata] = useState(false)
    const [showstartmodal, setShowstartmodal] = useState(false)
    const [jobs, setJobs] = useState([])
    const [formdata, setformdata] = useState({})
    const [modifymode, setmodifymode] = useState(false)
	const [jobsLoading, setJobsLoading] = useState(false)

    const getTemplates = (uid) => {
        axios.get(`/getformtemplates/${uid}`)
			.then(res => {
                setTemplates(res.data)
				// props.setTemplates(res.data)
				// props.setSelectedTemplate(res.data[0].id)
			})
			.catch(err => {
                console.log(err)
            })
    }


	// useEffect(() => {
	//      props.setSelectedTemplate(selectedTemplate.id)
	// }, [selectedTemplate])

	// useEffect(() => {
	//     setformdata({})
	//     setShowCForm(props.showCForm)
	//     setmodifymode(false)

	// }, [props.showCForm])


	const disableCForm = () => {
        setShowCForm(false)
		// props.setShowCForm(false)
		// props.setrefresh(prevdata => !prevdata)
    }

	const getjobs = () => {

		setShowstartmodal(true)
		setJobsLoading(true)

        axios.get(`/getjobswithforms?userid=${props.uid}`)
			.then(result => {
                setJobs(result.data)
                setSelectedJob(-1)
			})
			.catch(err => {
                console.log(err)
				setShowstartmodal(false)
            })
			.finally(() => setJobsLoading(false))
    }


    const startcampaign = (event) => {
		if (selectedJob > 0) {
            setJobsLoading(true)
			axios.get(`/startformcampaign?tid=${selectedTemplate.id}&jid=${selectedJob}&mid=${mode}&startedby=${props.uid}`)
				.then(res => {
					setJobsLoading(false)
                    alert(`Campaign Started!`)
                    setShowstartmodal(false)
					// props.setCampaignRefresh(prevdata => !prevdata)
				})
				.catch(err => {
                    console.log(err)
                })
		} else {
            alert('You need to select a list to start a campaign')
		}

    }

    const handleModeCheckbox = (event) => {
        if(event.target.checked) setMode(1)
        else setMode(2)
    }

    const resetMode = () =>{
        setMode(2)// resetting mode back to the default manual review mode
    }

    const handlejobselect = (event) => {
        setSelectedJob(event.target.value)
    }

	useEffect(() => {
        getTemplates(props.uid)
    }, [showCForm, updatingdata])


    const deleteTemplate = (template) => {
        setSelectedTemplate(template)
        if (window.confirm('Are you sure you want to delete this Template?')) {
            setUpdatingdata(true)
            axios.delete(`/deleteformtemplate/${template.id}`)
				.then(res => {
                    setUpdatingdata(false)
				})
                .catch(err => console.log(err))
		}
    }

    const modifyTemplate = (template) => {
        setShowCForm(true)
        setmodifymode(true)
        setSelectedTemplate(template)
        axios.get(`/getformdata/${template.id}`)
            .then((res) => {
                setformdata(res.data[0])
			})
            .catch(error => console.log(error))

    }

	return (
		<React.Fragment>
			<div className="w-100 h-100 d-flex flex-column overflow-hidden">
				<Tab.Container defaultActiveKey="formtemplates" activeKey="formtemplates">
					<Nav variant="tabs" className="fs-6 border">
						<Nav.Item>
							<Nav.Link eventKey="formtemplates">Form Templates</Nav.Link>
						</Nav.Item>
						<Nav.Item className="ms-auto">
							<Button title="New Form Template" className={`btn btn-primary mx-1 float-right`} onClick={() => setShowCForm(true)}>
								<i class={`bi bi-plus text-white`} style={{fontWeight: "bold"}}></i>
							</Button>
							<Button title="Refresh" className={`btn btn-primary mx-1 float-right`} onClick={getTemplates}>
								<i class={`bi bi-arrow-repeat`}></i>
							</Button>
						</Nav.Item>
					</Nav>
					<Tab.Content className="w-100" style={{height: "calc(100% - 34px)"}} animation={true} mountOnEnter={true} unmountOnExit={true}>
						<Tab.Pane className="w-100 h-100" eventKey="formtemplates" title="Campaigns">
							{/* <ContactFormTemplates setrefresh={setTemplateRefresh} refresh={templateRefresh} setCampaignRefresh={setCampaignRefresh} handleTabSelection={handleTabSelection} uid={props.uid} setSelectedTemplate={setSelectedTemplate} setTemplates={setTemplates} isCreateTemplateVisible={isCreateTemplateVisible} showCForm={showCForm} setShowCForm={setShowCForm} /> */}
							<div className={`h-100 overflow-auto d-flex flex-wrap`}>
								{templates.map((row, index) => (
									<Col
										xs={2}
										className="m-1"
										style={{
											width: "100%",
											minWidth: "199px",
											maxWidth: "calc(12% - 12px",
										}}>
										<Card key={index} onClick={() => setSelectedTemplate(templates[index])} title={`Template [ID-${row.id}]`} className={`text-primary ${row.id === selectedTemplate.id ? `border-primary shadow-lg` : ""}`}>
											<Card.Header className={`text-white bg-primary p-0 align-items-center`}>
												<div className="d-flex flex-row">
													<div className="p-2">
														<span>{`Template [ID-${row.id}]`}</span>
													</div>
												</div>
											</Card.Header>
											<Card.Body className="p-2 d-flex align-content-end flex-column">
												<Card.Text>
													<Row className="mx-0 border-top">
														<Col className="p-0">
															<b>Name</b>
														</Col>
													</Row>
													<Row className="mx-0">
														<Col className="ps-1">{row.cname}</Col>
													</Row>
													<Row className="mx-0 border-top">
														<Col className="p-0">
															<b>Description</b>
														</Col>
													</Row>
													<Row className="mx-0">
														<Col className="ps-1">{row.cdescription}</Col>
													</Row>
													<Row className="mx-0 border-top">
														<Col className="p-0">
															<b>Created By</b>
														</Col>
													</Row>
													<Row className="mx-0  border-bottom">
														<Col className="ps-1">{row.uname}</Col>
													</Row>
												</Card.Text>
												<Card.Text className={`m-0 p-0`}>
													<Row className={`w-100 m-0`}>
														<Col className="p-0 d-flex">
															<Button
																id={`btnm-${row.id}`}
																size="sm"
																disabled={row.runningjobs === 0 ? false : true}
																variant="outline-primary"
																style={{borderRadius: "50%"}}
																className="me-1 border border-gray"
																onClick={() => {
																	modifyTemplate(templates[index]);
																}}>
																<i class="bi bi-pencil-square"></i>
															</Button>
															<Button
																id={`btnc-${row.id}`}
																size="sm"
																variant="outline-primary"
																title="Start Campaign"
																style={{borderRadius: "50%"}}
																className="me-1 border border-gray"
																onClick={() => {
																	getjobs();
																	setSelectedTemplate(templates[index]);
																}}>
																<i class="bi bi-play"></i>
															</Button>
															<Button
																id={`btnd-${row.id}`}
																size="sm"
																disabled={row.runningjobs === 0 ? false : true}
																variant="outline-primary"
																title="Delete Template"
																style={{borderRadius: "50%"}}
																className="ms-auto border border-gray"
																onClick={() => {
																	deleteTemplate(templates[index]);
																}}>
																<i className="bi bi-trash"></i>
															</Button>
														</Col>
													</Row>
												</Card.Text>
											</Card.Body>
										</Card>
									</Col>
								))}
							</div>
						</Tab.Pane>
					</Tab.Content>
				</Tab.Container>
			</div>

			<Modal show={showCForm} onHide={disableCForm} centered size="lg" backdrop="static">
				<Modal.Header closeButton={true}>
					<div className="fs-6">{modifymode ? "Modify Template" : "Create Template"}</div>
				</Modal.Header>
				<Modal.Body className="m-1 p-3">
					<CForm modifymode={modifymode} formdata={formdata} disableCForm={disableCForm} uid={props.uid} tid={selectedTemplate.id} />
				</Modal.Body>
			</Modal>

			<Modal
				show={showstartmodal}
				onHide={() => {
					setShowstartmodal(false);
					resetMode();
				}}
				centered
				size="lg">
				<Modal.Header closeButton={true}>
					<div className="fs-6">Start Campaign</div>
				</Modal.Header>
				<Modal.Body className="m-1 p-3">
					<Form>
						<Form.Group className="mb-3">
							<Form.Label>Lists</Form.Label>
							<Form.Select id="selectOptions" onChange={handlejobselect}>
								<option key="default" value={-1}>
									Select list to start a campaign
								</option>
								{jobs.map((option, index) => (
									<option key={index} value={option.id} disabled={[2, 3].includes(option.status) ? false : true}>
										{option.name} [ID-{props.uid}00{option.id}] ( {option.formcount} forms found )
									</option>
								))}
							</Form.Select>
						</Form.Group>

						<div className="p-2 d-flex justify-content-between">
							<div>
								<div class="form-check form-switch">
									<input type="checkbox" id="modecheck" onChange={handleModeCheckbox} class="form-check-input" />

									<label title="" for="modecheck" class="form-check-label">
										Fast-Forward Mode
									</label>

									<OverlayTrigger key="tooltip-mode" placement={"right"} overlay={<Tooltip id={`tooltip-mode}`}>In fast-Forward mode, all automatable forms will be submitted. In default Manual Review mode, You can review which Forms you actually want to submit.</Tooltip>}>
										<i class="bi bi-question-circle-fill ps-1"></i>
									</OverlayTrigger>
								</div>
							</div>
							<div>
								<Button className="mx-1" title="Cancel" variant="secondary" onClick={() => setShowstartmodal(false)}>
									Cancel
								</Button>

								
									<Button className={`mx-1`} title="Start a campaign with selected Template" variant="primary" onClick={startcampaign}>
									{jobsLoading ? (
										<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
								) : (<>Start Campaign</>)}
									</Button>
							
							</div>
						</div>
					</Form>
				</Modal.Body>
				<Modal.Footer style={{justifyContent: "flex-start"}}>
					<p className="mx-1">Note : You cannot run campaign for a paused list. Please wait for the List to get completed or Stop the crawl Job to run a campaign on that list</p>
				</Modal.Footer>
			</Modal>
		</React.Fragment>
	);
}

export default ContactFormTemplates;