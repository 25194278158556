import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { Row, Col, Button, Card, Modal, Form, OverlayTrigger, Tooltip,Tab, Nav } from 'react-bootstrap'
import {useParams} from 'react-router-dom';
import JoditEditor from 'jodit-react';
import CreateCampaign from './createcampaign';

function EmailTemplates(props) {
    const [templates, setTemplates] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState({})
    const [showForm, setShowForm] = useState(false)
    const [updatingdata, setUpdatingdata] = useState(false)
    const [showstartmodal, setShowstartmodal] = useState(false)
    
    const [modifymode, setmodifymode] = useState(false)
    const [validated, setValidated] = useState(false);
    const [formData,setFormData]=useState({})
    const attachmentRef=useRef()

    const editor = useRef(null);
	const config =useMemo(()=>{ return {
        readonly: false, // all options from https://xdsoft.net/jodit/docs/,
        placeholder: 'Start typing...',
        height:375
    }},[]);

    const [guidelinesShown,setGuidelinesShown]=useState(false)
    let {campaignid}=useParams()
    
    const getTemplates = (uid) => {
        axios.get(`/emailtemplates/userid/${uid}`)
            .then(res => {
                setTemplates(res.data)
                // props.setTemplates(res.data)
                // props.setSelectedTemplate(res.data[0].id)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const disableCForm = () => {
        setShowForm(false)
        // props.setShowForm(false)
        // props.setrefresh(prevdata => !prevdata)
    }

    useEffect(()=>{
        
        if(parseInt(campaignid)>0)
        {
            setShowstartmodal(true)
        }
    },[])

    useEffect(() => {
        getTemplates(props.uid)
    }, [showForm, updatingdata])


    const deleteTemplate = (template) => {
        setSelectedTemplate(template)
        if (window.confirm('Are you sure you want to delete this Template?')) {
            setUpdatingdata(true)
            axios.delete(`/emailtemplates/${template.id}`)
                .then(res => {
                    setUpdatingdata(false)
                })
                .catch(err => alert('Something wrong happened. Can you please try after some time?'))
        }
    }

    const modifyTemplate = (template) => {

        setSelectedTemplate(template)
        axios.get(`/emailtemplates/${template.id}`)
            .then((res) => {
                let template=res.data[0]
                let attachments=[]
                for(let a of JSON.parse(template.attachments))
                    attachments.push(a.filename)
                template.attachments=attachments.join(', ')
                 setFormData(template)
            })
            .catch(error => console.log(error))

            setmodifymode(true)
            setShowForm(true)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false ) {
            event.preventDefault();
            event.stopPropagation();
        }
        else if (form.checkValidity()===true ) {
            event.preventDefault();
            event.stopPropagation();

            let data = new FormData() ;
    
            for (const [key, val] of Object.entries(formData))
                data.set(key, val);
            
            data.set('createdby', props.uid);
            for(let file of attachmentRef.current.files)
                data.set(file.name, file);

            const response=await fetch('/api/emailtemplates',{
                method:"POST",
                body:data
            })

            if(response.ok){
                alert('Email template created')
                setFormData({})
                setValidated(true)
                setShowForm(false)
            }
            else{
                alert('Something wrong happened. Can you please try after some time?')
            }
        }

        
    };

    const handleUpdate = async (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false ) {
            event.preventDefault();
            event.stopPropagation();
        }
        else if (form.checkValidity()===true ) {
            event.preventDefault();
            event.stopPropagation();

            let data = new FormData() ;
            for (const [key, val] of Object.entries(formData))
                data.set(key, val);
            data.set('modifiedby', props.uid);

            for(let file of attachmentRef.current.files)
                data.set(file.name, file);

            const response=await fetch('/api/emailtemplates',{
                method:"PUT",
                body:data
            })

            if(response.ok){
                alert('Email template Modified')
                setFormData({})
                setValidated(true)
                setShowForm(false)
            }
            else{
                alert('Something wrong happened. Can you please try after some time?')
            }
        }

        setValidated(true);
    }

    return (
        <React.Fragment>

            <div className='w-100 h-100 d-flex flex-column overflow-hidden'>
                <Tab.Container defaultActiveKey="emailtemplates">
                    <Nav variant='tabs' className='fs-6 border' >
                        <Nav.Item><Nav.Link eventKey="emailtemplates">Email Templates</Nav.Link></Nav.Item>
                        <Nav.Item className='ms-auto'>
                            <Button title="New Form Template" className={`btn btn-primary mx-1 float-right`} onClick={() => setShowForm(true)}><i class={`bi bi-plus text-white`} style={{fontWeight:'bold'}}></i></Button>
                            <Button title="Refresh" className={`btn btn-primary mx-1 float-right`} onClick={getTemplates}><i class={`bi bi-arrow-repeat`}></i></Button>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content className='w-100' style={{ height: "calc(100% - 34px)" }} animation={true} mountOnEnter={true} unmountOnExit={true} >
                        <Tab.Pane className='w-100 h-100' eventKey="emailtemplates" title="Email Templates">
                            {/* <ContactFormTemplates setrefresh={setTemplateRefresh} refresh={templateRefresh} setCampaignRefresh={setCampaignRefresh} handleTabSelection={handleTabSelection} uid={props.uid} setSelectedTemplate={setSelectedTemplate} setTemplates={setTemplates} isCreateTemplateVisible={isCreateTemplateVisible} showForm={showForm} setShowForm={setShowForm} /> */}
                            
                            <Row>
                                {templates.map((row, index) => (
                                    <Col className='m-1' style={{ width: '100%', minWidth: '199px', maxWidth: 'calc(12% - 12px' }}>
                                        <Card
                                            key={index}
                                            onClick={() => setSelectedTemplate(templates[index])}
                                            title={`Template [ID-${row.id}]`}
                                            className={`text-primary ${(row.id === selectedTemplate.id) ? `border-primary shadow-lg` : ''}`}
                                        >
                                            <Card.Header className={`text-white bg-primary p-0 align-items-center`}>
                                                <div className="d-flex flex-row">
                                                    <div className="p-2"><span>{`Template [ID-${row.id}]`}</span></div>
                                                </div>
                                            </Card.Header>
                                            <Card.Body className='p-2 d-flex align-content-end flex-column'>
                                                <Card.Text>
                                                    <Row className="mx-0 border-top"><Col className="p-0"><b>Name</b></Col></Row>
                                                    <Row className="mx-0"><Col className="ps-1">{row.title}</Col></Row>
                                                    <Row className="mx-0 border-top"><Col className="p-0"><b>Description</b></Col></Row>
                                                    <Row className="mx-0"><Col className="ps-1">{row.description}</Col></Row>
                                                </Card.Text>
                                                <Card.Text className={`m-0 p-0`}>
                                                    <Row className={`w-100 m-0`}>
                                                        <Col className="p-0 d-flex">
                                                            <Button id={`btnm-${row.id}`} size="sm" variant="outline-primary" style={{ borderRadius: "50%" }} className="me-1 border border-gray" onClick={() => { modifyTemplate(templates[index]) }}><i class="bi bi-pencil-square"></i></Button>
                                                            <Button id={`btnc-${row.id}`} size="sm" variant="outline-primary" title="Start Campaign" style={{ borderRadius: "50%" }} className="me-1 border border-gray" onClick={() => {setSelectedTemplate(templates[index]); setShowstartmodal(true)}}><i class="bi bi-play"></i></Button>
                                                            <Button id={`btnd-${row.id}`} size="sm" variant="outline-primary" title="Delete Template" style={{ borderRadius: "50%" }} className="ms-auto border border-gray" onClick={() => { deleteTemplate(templates[index]) }}><i className="bi bi-trash"></i></Button>
                                                        </Col>
                                                    </Row>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>

            <Modal show={showForm} onHide={disableCForm} className='shadow-lg' centered size="lg" enforceFocus={false} >
                <Modal.Header className='py-2 bg-primary text-white'>
                    <div className="fs-5">{modifymode ? 'Modify Template' : 'Create Template'}</div>
                </Modal.Header>
                <Modal.Body className="m-1 p-4"  style={{height:'646pt'}}>
                    <div className={`d-flex flex-column ${(guidelinesShown||modifymode)?'d-none':'d-block'}`}>
                    
                        <h4>Guidelines for email content according to CAN spam Act</h4>
                        <br/>
                        <ol>
                            <li>
                                <p><strong>Do Not Use Deceptive Subject Lines:</strong>  The subject line must accurately reflect the content of the message.</p>
                            </li>
                            <li>
                                <p><strong>Identify the Message as an Ad:</strong> The law gives you a lot of leeway in how to do this, but you must disclose clearly and conspicuously that your message is an advertisement.
                                </p>
                            </li>
                            <li>
                                <p><strong>Tell Recipients Where You’re Located:</strong> Your message must include your valid physical postal address. This can be your current street address, a post office box you’ve registered with the U.S. Postal Service, or a private mailbox you’ve registered with a commercial mail receiving agency established under Postal Service regulations.
                                </p>
                            </li>
                            <li>
                                <p><strong>Tell Recipients How to Opt-Out of Receiving Future Emails from You:</strong> Your message must include a clear and conspicuous explanation of how the recipient can opt-out of getting email from you in the future. Craft the notice in a way that’s easy for an ordinary person to recognize, read, and understand. A return email address or another easy Internet-based way to allow people to communicate their choice to you is required.</p>
                            </li>
                            <li>
                                <p><strong>Honor Opt-Out Requests Promptly:</strong>Any opt-out mechanism you offer must be able to process opt-out requests for at least 30 days after you send your message. You must honor a recipient’s opt-out request within 10 business days. You can’t charge a fee, require the recipient to give you any personally identifying information beyond an email address, or make the recipient take any step other than sending a reply email or visiting a single page on an Internet website as a condition for honoring an opt-out request.</p>
                            </li>
                            <li>
                                <p><strong>Monitor What Others Are Doing on Your Behalf:</strong>Even if you hire another company to handle your email marketing, you can’t contract away your legal responsibility to comply with the law. Both the company whose product is promoted in the message and the company that actually sends the message may be held legally responsible.</p>
                            </li>
                        </ol>
                        <div className='d-flex justify-content-end'>
                                    <Button className='mx-1' variant="secondary" onClick={() => { setShowForm(false);}}>
                                        Cancel
                                    </Button>
                                
                                    <Button type='submit' className={`mx-1`} variant="primary" onClick={()=>setGuidelinesShown(true)}>
                                        Next
                                    </Button>
                                
                                </div>
                    </div>
                    <div className={`d-flex flex-column ${guidelinesShown||modifymode?'d-block':'d-none'}`}>
                        <Form noValidate validated={validated} onSubmit={(e) => modifymode ? handleUpdate(e) : handleSubmit(e)}>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Group >
                                            <Form.Label className='fw-bold'>Title{<span style={{ color: 'red' }}>*</span>}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                // placeholder="Title"
                                                value={formData.title}
                                                name='title'
                                                onChange={handleChange}
                                                required
                                                // isInvalid={!formData.title}
                                                pattern={'^[a-zA-Z 0-9]+$'}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid title.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group >
                                            <Form.Label className='fw-bold'>Description</Form.Label>
                                            <Form.Control
                                                type='text'
                                                value={formData.description}
                                                name='description'
                                                onChange={handleChange}
                                            />
                                    </Form.Group>
                                </Col>
                                
                            </Row>
                            <Row className="mb-3 mt-2">
                            {/* <Col >
                                    <Form.Group >
                                            <Form.Label>To{<span style={{ color: 'red' }}>*</span>}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={formData.to||'{LEAD_EMAIL}'}
                                                name='to'
                                                onChange={handleChange}
                                                required
                                                pattern={'^[{}_,@a-zA-Z0-9.]+$'}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid to email.
                                            </Form.Control.Feedback>
                                    </Form.Group>
                                </Col> */}
                            <Col >
                                    <Form.Group >
                                            <Form.Label className='fw-bold'>Display Name (From){<span style={{ color: 'red' }}>*</span>}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="eg. ABC Enterprises"
                                                value={formData.displayname}
                                                name='displayname'
                                                onChange={handleChange}
                                                required
                                                pattern={'^[a-zA-Z 0-9]+$'}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid display name.
                                            </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* <Row className="mb-3">
                            <Col>
                                    <Form.Group >
                                            <Form.Label>cc</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={formData.cc}
                                                name='cc'
                                                onChange={handleChange}
                                                pattern={'^[{}_,@a-zA-Z0-9.]+$'}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid to CC.
                                            </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group >
                                            <Form.Label>bcc</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={formData.bcc}
                                                name='bcc'
                                                onChange={handleChange}
                                                pattern={'^[{}_,@a-zA-Z0-9.]+$'}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide valid BCC.
                                            </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row> */}
                            <Row className="mb-3">
                                <Col>
                                    <Form.Group >
                                            <Form.Label className='fw-bold'>Subject{<span style={{ color: 'red' }}>*</span>}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={formData.subject}
                                                name='subject'
                                                onChange={handleChange}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid subject.
                                            </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3 h-50">
                                <Col>
                                    <Form.Group >
                                            <Form.Label className='fw-bold'>Email Body{<span style={{ color: 'red' }}>*</span>}</Form.Label>
                                            <JoditEditor 
                                                ref={editor}
                                                value={formData.body}
                                                config={config}
                                                tabIndex={1} // tabIndex of textarea
                                                onBlur={newContent =>  setFormData({ ...formData, body: newContent })} // preferred to use only this option to update the content for performance reasons
                                                onChange={newContent => setFormData({ ...formData, body: newContent })}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid email body.
                                            </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label className='fw-bold'>Attachment(s)</Form.Label>
                                    <Form.Control type="file" name='attachments' ref={attachmentRef} multiple/>
                                    <Form.Label className='fw-bold'>{formData.attachments}</Form.Label>
                                </Form.Group>
                                </Col>
                            </Row>
                                            <Form.Control
                                                type="hidden"
                                                value={formData.id}
                                                name='id'
                                            />

                                <div className='d-flex justify-content-end'>
                                    <Button className='mx-1' variant="secondary" onClick={() => { setShowForm(false);setFormData({});setmodifymode(false);setValidated(false);setGuidelinesShown(false)}}>
                                        Cancel
                                    </Button>
                                
                                    <Button type='submit' className={`mx-1`} variant="primary">
                                        {modifymode===true ? 'Update' : 'Save'}
                                    </Button>
                                
                                </div>
                            
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showstartmodal} onHide={() => setShowstartmodal(false)} fullscreen={true}>
                <Modal.Header className='py-2 bg-primary text-white' >
                    <div className="fs-5">Start Email Campaign</div>
                </Modal.Header>
                <Modal.Body className="p-3 pb-0 w-100">
                    <CreateCampaign uid={props.uid} campaignid={campaignid} setShowstartmodal={setShowstartmodal} templateid={selectedTemplate.id}></CreateCampaign>
                </Modal.Body>
                {/* <Modal.Footer style={{ justifyContent: 'flex-start' }}>
                    <p className='mx-1'>Note : You cannot run campaign for a paused list. Either wait for the List to get completed or Stop the crawl Job to run a campaign for it</p>
                </Modal.Footer> */}
            </Modal>

        </React.Fragment>
    );
}

export default EmailTemplates;