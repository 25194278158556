import React, { Component } from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import LeadCharts from './leadcharts';
import LeadFacts from './leadfacts';
import LeafletMaps from "./maps";
import WebPage from './webpage';

import "bootstrap/dist/css/bootstrap.min.css";

class LeadTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeLeadTab: `${(this.props.view === 'leads') ? "charts" : "home"}` // Initial active key
        };
    }
   
    handleSelect = (key) => {
        this.setState({ activeLeadTab: key }); 
    }

    render() {
        const BLANK_URL = '/blank.html' // 'about:blank';
        function verifyURL(c) {
            return (c && (c.substring(0,4).toLowerCase() === "http")) ? c : BLANK_URL
        }
    
        const data = this.props.data.length > 0 ? this.props.data[0] : [];

        const homepage      = verifyURL(data.url);
        const contactpage   = verifyURL(data.contacturl);
        const aboutpage     = verifyURL(data.abouturl);
        const careerspage   = verifyURL(data.careersurl);
        const teampage      = verifyURL(data.leadershipurl);
        const pluscodepage  = data.pluscode ? `https://plus.codes/map/${data.pluscode}` : BLANK_URL;
        
        return (
            <div className='w-100 h-100 d-flex flex-column overflow-hidden'>
                <Tab.Container activeKey={this.state.activeLeadTab} onSelect={this.handleSelect}>
                    <Nav variant='tabs'>
                        {(this.props.view === 'leads') ? (<>
                        <Nav.Item><Nav.Link eventKey="charts"><i className="bi bi-bar-chart-line me-1" />Charts</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="geomap"><i className="bi bi-globe-americas me-1" />Map</Nav.Link></Nav.Item>
                        </>) : ''}
                        <Nav.Item><Nav.Link eventKey="home"><i className="bi bi-house me-1" />Home</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="contact">Contact</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="about">About</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="careers">Careers</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="team">Team</Nav.Link></Nav.Item>
                        {(this.props.view === 'leads') ? (<>
                        <Nav.Item><Nav.Link eventKey="pluscode"><i className="bi bi-geo-alt me-1" />Location</Nav.Link></Nav.Item>
                        </>) : ''}
                        {(data.owlerurl && (data.owlerurl !== '')) ? (
                        <Nav.Item><Nav.Link eventKey="facts"><i className="bi bi-info-circle me-1" />Facts</Nav.Link></Nav.Item>
                        ) : ''}
                    </Nav>
                    <Tab.Content className='w-100 h-100' animation="true" mountOnEnter={true} unmountOnExit={true} >
                        {(this.props.view === 'leads') ? (<>
                        <Tab.Pane className='w-100 h-100' eventKey="charts" title="Charts" >
                            <div id={`charts-${this.props.jid}`} style={{height: "calc(100%)"}}>
                                <LeadCharts jid={this.props.jid} bardata={this.props.bardata} sbardata={this.props.sbardata} piedata={this.props.piedata} lfu={this.props.lfu} />
                            </div>
                        </Tab.Pane>
                        <Tab.Pane className='w-100 h-100' eventKey="geomap" title="Geo Map">
                            <div id={`map-${this.props.jid}`} style={{height: "calc(100%)"}}>
                                <LeafletMaps job={this.props.jid} containerId="tabMapContainer" activeLeadTab={this.state.activeLeadTab} data={this.props.data}/>
                            </div>
                        </Tab.Pane>
                        </>) : ''}
                        <Tab.Pane className='w-100 h-100' eventKey="home"     title="Home"        ><WebPage data={homepage} /></Tab.Pane>
                        <Tab.Pane className='w-100 h-100' eventKey="contact"  title="Contact"     ><WebPage data={contactpage} /></Tab.Pane>
                        <Tab.Pane className='w-100 h-100' eventKey="about"    title="About"       ><WebPage data={aboutpage} /></Tab.Pane>
                        <Tab.Pane className='w-100 h-100' eventKey="careers"  title="Careers"     ><WebPage data={careerspage} /></Tab.Pane>
                        <Tab.Pane className='w-100 h-100' eventKey="team"     title="Team"        ><WebPage data={teampage} /></Tab.Pane>
                        {(this.props.view === 'leads') ? (<>
                        <Tab.Pane className='w-100 h-100' eventKey="pluscode" title="Geo Location"><WebPage data={pluscodepage} /></Tab.Pane>
                        </>) : ''}
                        <Tab.Pane className='w-100 h-100 overflow-auto' eventKey="facts" title="Facts" ><LeadFacts data={this.props.data} /></Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        )
    }
}

export default LeadTabs;